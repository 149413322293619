import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
    STORAGE_ACCESS_TOKEN,
    STORAGE_PROJECTS,
    STORAGE_AUTHORITIES,
} from "config";
import { getCookie } from "utils/cookie";
import { ENVIRONMENT } from "config/environment";
import api from "api";

interface IProps {
    public?: boolean;
}

class Page extends React.Component<IProps & RouteComponentProps> {
    public redirectToLogin() {
        if (window.location.pathname !== "/login") {
            window.location.href = `${ENVIRONMENT.innoecos_url}/login?timestamp=${+new Date()}`;
        }
    }
    public async componentDidMount() {
        this.init();
    }
    public async init() {
        const commonToken = getCookie(ENVIRONMENT.cookie_name);

        if (!commonToken) {
            this.redirectToLogin();
            return;
        }

        try {
            const response = await api.account.createToken(commonToken);
            window.localStorage.setItem(STORAGE_ACCESS_TOKEN, response.data.data.accessToken);

            const pagedAuthorities = await api.auth.getMyAuthorities();
            const authorities = pagedAuthorities.items;

            const pagedProjects = await api.basic.getAllProjects();
            const projects = pagedProjects.items;

            window.localStorage.setItem(STORAGE_AUTHORITIES, JSON.stringify(authorities));
            window.localStorage.setItem(STORAGE_PROJECTS, JSON.stringify(projects));

            this.props.history.push("/");
        } catch (error) {
            this.redirectToLogin();
        }
    }
    public render() {
        return <>
            { this.props.children }
        </>;
    }
}

export default withRouter(Page);

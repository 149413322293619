import PortalPage from "pages/portal";

const defaultRoutes = [
    { path: "/", component: PortalPage },
    // { path: "/portal", component: PortalPage },
];

export const routes = [
    ...defaultRoutes,
];

import { requestIns } from "utils/request";
import { IPaged, IAuthority } from "models";
import { API_OAUTH_URL } from "config";

export async function getMyAuthorities(): Promise<IPaged<IAuthority>> {
    const response = (await requestIns.get(
        `${API_OAUTH_URL}/users/me/authorities`,
        {
            params: {
                "$paging.page": 0,
                "$paging.size": 99999,
            },
        },
    )).data;

    return response;
}

import { requestIns } from "utils/request";
import { IUser } from "models";

export async function getUser(): Promise<IUser> {
    const response = (
        await requestIns.get(`/user`)
    ).data;

    return response;
}

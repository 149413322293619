import React from "react";
import { IUser } from "models";
import styles from "./index.module.css";

interface IProps {
    user: IUser;
}

class Avatar extends React.Component<IProps> {
    public render() {
        const { user } = this.props;

        return <div className={styles.container}>
            <img src={user.avatar} alt="Avatar"/>
        </div>;
    }
}

export default Avatar;

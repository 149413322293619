import * as user from "./user";
import * as account from "./account";
import * as auth from "./auth";
import * as basic from "./basic";

export default {
    user,
    account,
    auth,
    basic,
};

const PREFIX_BR = "apps/webbr";
const PREFIX_BV = "apps/webbv";
const PREFIX_DSBV = "apps/dscloud-webbv";

export const PORTAL_MENUS = [
  {
    label: "基本设置",
    children: [
      // {
      //     icon: require("static/images/portal/icon_xiangmuguanli.png"),
      //     title: "项目管理",
      //     route: `${PREFIX_BR}/project`,
      //     authKeys: ["ENTER_WEB_PROJECT_MANAGER"],
      // },
      {
        icon: require("static/images/portal/icon_kongjianguanli.png"),
        title: "空间管理",
        route: `${PREFIX_BR}/space`,
        authKeys: ["ENTER_WEB_SPACE_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_qiyeguanli.png"),
        title: "企业管理",
        route: `${PREFIX_BR}/enterprise`,
        authKeys: ["ENTER_WEB_ENTERPRISE_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_zuzhiquanxian.png"),
        // title: "组织及权限管理",
        title: "员工管理",
        route: `${PREFIX_BR}/organization`,
        authKeys: ["ENTER_WEB_DEPARTMENT_AND_STAFF_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_ruzhuguanli.png"),
        title: "入驻管理",
        route: `${PREFIX_BR}/enterprises`,
        authKeys: ["ENTER_WEB_SETTLED_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_fuwuguanli.png"),
        title: "服务管理",
        route: `${PREFIX_BV}/serve`,
        authKeys: ["APP_SERVICE_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_fuwuguanli.png"),
        title: "合作商户管理",
        route: `${PREFIX_DSBV}/cooperationWithMerchants`,
        authKeys: ["APP_SERVICE_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_fuwubangdingguanli.png"),
        title: "服务绑定管理",
        route: `${PREFIX_BV}/serveb`,
        authKeys: ["APP_SERVICE_BIND_MANAGER"],
      },
    ],
  },
  {
    label: "物业服务",
    children: [
      {
        icon: require("static/images/portal/icon_tongzhigonggao.png"),
        title: "通知公告",
        route: `${PREFIX_BR}/announceList`,
        authKeys: ["ENTER_WEB_NOTICE_MANAGER"],
      },
      // {
      //     icon: require("static/images/portal/icon_yuanqugonggao.png"),
      //     title: "园区公告",
      //     route: `${PREFIX_BR}/announcement/client/message`,
      //     authKeys: ["ENTER_WEB_PARK_NOTICE_MANAGER"],
      // },
      {
        icon: require("static/images/portal/icon_zhinengxungeng.png"),
        title: "智能巡更",
        route: `${PREFIX_BR}/prompter/record`,
        authKeys: ["ENTER_WEB_INTELLIGENT_PORTAL_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_zhinengxuncha.png"),
        title: "智能巡查",
        route: `${PREFIX_BR}/patrol`,
        authKeys: ["ENTER_WEB_INTELLIGENT_CHECK_MANAGER"],
      },
      // {
      //   icon: require("static/images/portal/icon_gongdanfuwu.png"),
      //   title: "工单服务(废弃)",
      //   route: `${PREFIX_BR}/workorder`,
      //   authKeys: ["ENTER_WEB_ORDER_SERVICE_MANAGER"],
      // },
      {
        icon: require("static/images/portal/help_or_feekback.png"),
        title: "帮助与反馈",
        route: `${PREFIX_BV}/feedbackList`,
        authKeys: ["HELP_AND_FEEKBACK"],
      },
      // {
      //   icon: require("static/images/portal/icon_baoshibaoxiu.png"),
      //   title: "报事报修",
      //   route: `${PREFIX_BR}/workorder/client`,
      //   authKeys: ["ENTER_WEB_REPAIR_MANAGER"],
      // },
      {
        icon: require("static/images/portal/icon_zhengjianguanli.png"),
        title: "证件管理",
        route: `${PREFIX_BR}/certificate`,
        authKeys: ["ENTER_WEB_CERTIFICATES_MANAGER"],
      },
      // {
      //     icon: require("static/images/portal/icon_zhengjianbanli.png"),
      //     title: "证件办理",
      //     route: ``,
      //     authKeys: ["ENTER_WEB_CERTIFICATES_OPERATE"],
      // },
      // {
      //     icon: require("static/images/portal/icon_changdiguanli.png"),
      //     title: "场地管理",
      //     route: `${PREFIX_BV}/site`,
      //     authKeys: ["ENTER_WEB_PLACE_MANAGER"],
      // },
      // {
      //     icon: require("static/images/portal/icon_changdiyuyue.png"),
      //     title: "场地预约",
      //     route: `${PREFIX_BV}/reservation/bookingSite`,
      //     authKeys: ["ENTER_WEB_PLACE_SUBSCRIBE_MANAGER"],
      // },
      {
        icon: require("static/images/portal/icon_wenjuanguanli.png"),
        title: "问卷管理",
        route: `${PREFIX_BR}/subject`,
        authKeys: ["ENTER_WEB_QUESTIONNAIRE_MANAGER"],
      },
      // {
      //     icon: require("static/images/portal/icon_canyuwenjuan.png"),
      //     title: "参与问卷",
      //     route: `${PREFIX_BR}/subject/client`,
      //     authKeys: ["ENTER_WEB_JOIN_QUESTIONNAIRE"],
      // },
      {
        icon: require("static/images/portal/icon_kefuguanli.png"),
        title: "客服管理",
        route: ``,
        authKeys: ["ENTER_WEB_CUSTOMER_SERVICE_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "门禁管理",
        route: `${PREFIX_BR}/access`,
        authKeys: ["ENTER_WEB_ACCESS_CONTROL_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "门禁管理（新）",
        route: `${PREFIX_DSBV}/accreditList`,
        authKeys: ["ENTER_WEB_ACCESS_CONTROL_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_fenlianshibie.png"),
        title: "人脸通行",
        route: `${PREFIX_BR}/face`,
        authKeys: ["ENTER_WEB_ACCESS"],
      },
      {
        icon: require("static/images/portal/icon_baoshishenfen.png"),
        title: "二维码身份",
        route: `${PREFIX_BR}/ncp`,
        authKeys: ["ENTER_WEB_SURVEILLANCE"],
      },
      {
        icon: require("static/images/portal/icon_fangkeuyaoqing.png"),
        title: "访客通行",
        route: `${PREFIX_BR}/visitor`,
        authKeys: ["VISITOR_WEB_LIST"],
      },
      {
        icon: require("static/images/portal/icon_shenbao.png"),
        title: "申报设置",
        route: `${PREFIX_BR}/declare`,
        authKeys: ["DECLARATION_SETTING"],
      },
      {
        icon: require("static/images/portal/icon_shenbao.png"),
        title: "园区申报",
        route: `${PREFIX_BR}/declare-review`,
        authKeys: ["ENTER_WEB_DECLARATION_MANAGER"],
      },
      // {
      //     icon: require("static/images/portal/icon_shenbao.png"),
      //     title: "智能跑道",
      //     route: `${PREFIX_BR}/track`,
      //     authKeys: [],
      // },
      // {
      //   icon: require("static/images/portal/icon_chezhengguanli.png"),
      //   title: "车证管理",
      //   route: `${PREFIX_BV}/carLicense`,
      //   authKeys: ["VEHICLE_LICENSE"],
      // },
      {
        icon: require("static/images/portal/icon_chezhengguanli.png"),
        title: "车证管理（新）",
        route: `${PREFIX_DSBV}/longTermRentalCarLicenseOrder`,
        authKeys: ["VEHICLE_LICENSE"],
      },
      {
        icon: require("static/images/portal/icon_chezhengguanli.png"),
        title: "停车场管理",
        route: `${PREFIX_DSBV}/parkingLotManagement`,
        authKeys: ["DEPOT_SYSTEM"],
      },
      // {
      //     icon: require("static/images/portal/icon_chezhengguanli.png"),
      //     title: "新的车证管理",
      //     route: `${PREFIX_DSBV}/longTermRentalCarLicenseOrder`,
      //     authKeys: ["VEHICLE_LICENSE"],
      // },
      // {
      //   icon: require("static/images/portal/icon_chezhengpiaowu.png"),
      //   title: "车证票务",
      //   route: `${PREFIX_BV}/financial`,
      //   authKeys: ["FINANCIAL_AFFAIRS"],
      // },
      {
        icon: require("static/images/portal/icon_metting.png"),
        title: "会议室管理",
        route: `${PREFIX_DSBV}/conferenceRoomList`,
        authKeys: ["METTING_MANAGEMENT"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "梯控管理",
        route: `${PREFIX_DSBV}/liftEquipmentManage`,
        authKeys: ["ENTER_WEB_ACCESS_CONTROL_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "社区门禁管理",
        route: `${PREFIX_BR}/communityAcs`,
        authKeys: ["ACCESS_MENU_WEB_DOOR_COMMUNITY"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "社区访客管理",
        route: `${PREFIX_BR}/communityVisitors`,
        authKeys: ["VISITOR_MANAGEMENT_COMMUNITY"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "社区消息通知",
        route: `${PREFIX_BR}/communityNotice`,
        authKeys: ["MESSAGE_NOTIFICATION"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "社区管理",
        route: `${PREFIX_DSBV}/community`,
        authKeys: ["COMMUNITY_MANAGEMENT"],
      },
      {
        icon: require("static/images/portal/icon_menjinguanli.png"),
        title: "居民管理",
        route: `${PREFIX_DSBV}/resident`,
        authKeys: ["RESIDENT_MANAGER_COMMUNITY"],
      },
    ],
  },
  {
    label: "社群服务",
    children: [
      {
        icon: require("static/images/portal/icon_huodongguanli.png"),
        title: "活动管理",
        route: `${PREFIX_BV}/activity`,
        authKeys: ["ENTER_WEB_ACTIVITY_MANAGER"],
      },
      // {
      //     icon: require("static/images/portal/icon_yuanquhuodong.png"),
      //     title: "园区活动",
      //     route: `${PREFIX_BV}/activityList`,
      //     authKeys: ["ENTER_WEB_PARK_ACTIVITY_MANAGER"],
      // },
      {
        icon: require("static/images/portal/icon_luntanguanli.png"),
        title: "论坛管理",
        route: `${PREFIX_BR}/forum`,
        authKeys: ["ENTER_WEB_FORUM_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_yuanquhuodong.png"),
        title: "推广位管理",
        route: `${PREFIX_BR}/promote`,
        authKeys: ["AD_MENU_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_iFriend.png"),
        title: "I友圈首页帖子管理",
        route: `${PREFIX_BV}/homePageManage`,
        authKeys: ["IYOU_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_iFriend.png"),
        title: "信息流管理",
        route: `${PREFIX_DSBV}/classAdmin`,
        authKeys: ["IYOU_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_iFriend.png"),
        title: "i友圈管理",
        route: `${PREFIX_DSBV}/authenticationManagement`,
        authKeys: ["IYOU_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_adManage.png"),
        title: "广告位管理",
        route: `${PREFIX_BV}/advertisingManagement`,
        authKeys: ["AD_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_adManage.png"),
        title: "广告位管理(新)",
        route: `${PREFIX_BV}/newAdvertisingManagement`,
        authKeys: ["AD_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_mokuaiguanli.png"),
        title: "首页模块管理",
        route: `${PREFIX_BV}/homepageSelection`,
        authKeys: ["HOMEPAGE_MODULES_MANAGE"],
      },
      {
        icon: require("static/images/portal/icon_activity_management.png"),
        title: "活动报名管理",
        route: `${PREFIX_DSBV}/regManage`,
        authKeys: ["ACTIVITY_ENROLL_MANAGEMENT"],
      },
      {
        icon: require("static/images/portal/active_show_management.png"),
        title: "活动展示管理",
        route: `${PREFIX_BV}/activityDisplay`,
        authKeys: ["ACTIVITY_SHOW_MANAGEMENT"],
      },
      {
        icon: require("static/images/portal/park_introduce.png"),
        title: "园区介绍管理",
        route: `${PREFIX_BV}/oneStopService`,
        authKeys: ["PARK_INTRODUCE_MANAGEMENT"],
      },
    ],
  },
  {
    label: "生活服务",
    children: [
      {
        icon: require("static/images/portal/icon_yundongsaishi.png"),
        title: "运动赛事",
        route: ``,
        authKeys: ["ENTER_WEB_MATCH_MANAGER"],
      },
    ],
  },
  {
    label: "企业服务",
    children: [
      {
        icon: require("static/images/portal/icon_chanpinguanli.png"),
        title: "产品管理",
        route: ``,
        authKeys: ["ENTER_WEB_PRODUCT_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_fuwushenqing.png"),
        title: "服务申请",
        route: ``,
        authKeys: ["ENTER_WEB_SERVICE_APPLY"],
      },
      {
        icon: require("static/images/portal/icon_zhengceguanli.png"),
        title: "政策管理",
        route: ``,
        authKeys: ["ENTER_WEB_POLICY_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_zhengcezixun.png"),
        title: "政策咨询",
        route: `${PREFIX_BR}/policy`,
        authKeys: ["ENTER_WEB_POLICY_CONSULTATION"],
      },
      {
        icon: require("static/images/portal/icon_zhaopinguanli.png"),
        title: "招聘管理",
        route: `${PREFIX_BV}/recruitment`,
        authKeys: ["ENTER_WEB_RECRUIT_MANAGER"],
      },
      {
        icon: require("static/images/portal/icon_qiyeguanli.png"),
        title: "企业服务",
        route: `/loginPage.html`,
        authKeys: ["ENTER_WEB_ENTERPRISE_SERVICE"],
      },
    ],
  },
];

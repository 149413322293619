import axios from "axios";
import { STORAGE_ACCESS_TOKEN } from "config";

export const requestIns = axios.create();

requestIns.interceptors.request.use(
    config => {
        const token = localStorage.getItem(STORAGE_ACCESS_TOKEN);
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
);

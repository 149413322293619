export const isDevelopment = process.env.NODE_ENV === "development";

// export const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN || "https://sit.innoecos.cn";

export const BASENAME = "/";

export const CLIENT_ID = "000af8f0abc00000";
export const CLIENT_SECRET = "1+T6TyyWywUEC0/C1YWqZ1Ut";

export const API_OAUTH_URL = "/uaa";
export const API_PROJECT_URL = `/api/project`;

export const STORAGE_ACCESS_TOKEN = "DS_ACCESS_TOKEN";
export const STORAGE_REFRESH_TOKEN = "DS_REFRESH_TOKEN";
export const STORAGE_AUTHORITIES = "DS_AUTHORITIES";
export const STORAGE_PROJECTS = "DS_PROJECTS";

import _ from "lodash";
import { IAuthority, IProject } from "models";
import { STORAGE_AUTHORITIES, STORAGE_PROJECTS } from "config";

export class Auth {
    private authorities: IAuthority[];
    private projects: IProject[];

    constructor() {
        this.authorities = this.getAuthorities();
        this.projects = this.getProjects();
    }

    public getAuthedProjects(authorityKeys: string[]) {
        const projectIdCollection: string[][] = [];

        authorityKeys.forEach(authorityKey => {
            const authProjectIds = this.getProjectIdSet(authorityKey);
            projectIdCollection.push(authProjectIds);
        });

        const authedProjectIds =  _.intersection(...projectIdCollection);

        const authedProjects: IProject[] = [];

        authedProjectIds.forEach(projectId => {
            const project = this.projects.find(x => x.id === projectId);

            if (project) {
                authedProjects.push(project);
            }
        });

        return authedProjects;
    }

    public hasRangeAuthorityWithoutProject(authorityKeys: string[]) {
        let flag = true;

        authorityKeys.forEach(authorityKey => {
            if (this.authorities.filter(x => x.authority === authorityKey).length <= 0) {
                flag = false;
            }
        });

        return flag;
    }

    public hasAuthority(projectId: string, authorityKeys: string[]) {
        const authedProjects = this.getAuthedProjects(authorityKeys);

        return !!authedProjects.find(x => x.id === projectId);
    }

    public hasRangeAuthority(authorityKeys: string[]) {
        const authedProjects = this.getAuthedProjects(authorityKeys);

        return authedProjects.length > 0;
    }

    private getAuthorities() {
        const jsonAuthorities = window.localStorage.getItem(STORAGE_AUTHORITIES);

        try {
            const authorities = JSON.parse(jsonAuthorities || "");
            return authorities;
        } catch (error) {
            return [];
        }
    }

    private getProjects() {
        const jsonProjects = window.localStorage.getItem(STORAGE_PROJECTS);

        try {
            const projects = JSON.parse(jsonProjects || "");
            return projects;
        } catch (error) {
            return [];
        }
    }

    private getProjectIdSet(authorityKey: string) {
        const projectIds: string[] = [];

        this.authorities
        .filter(x => x.authority === authorityKey)
        .forEach(x => {
            const projectId = x.scope.split(":")[1];
            if (projectIds.indexOf(projectId) < 0) {
                projectIds.push(projectId);
            }
        });

        return projectIds;
    }
}

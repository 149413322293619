import axios from "axios";
export const ENVIRONMENT = {
    portal_url: "",
    oa_app_key: "",
    innoecos_url: "",
    cookie_name: "",

    init: async () => {
        const config = (
            await axios.get(`/api/dscloud-account-center/parkConfig`)
        ).data as {
            portal_url: string,
            oa_app_key: string,
            innoecos_url: string,
            cookie_name: string,
        };

        ENVIRONMENT.innoecos_url = config.innoecos_url;
        ENVIRONMENT.oa_app_key = config.oa_app_key;
        ENVIRONMENT.portal_url = config.portal_url;
        ENVIRONMENT.cookie_name = config.cookie_name;
    },
};

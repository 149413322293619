import { requestIns } from "utils/request";
import { IPaged, IProject } from "models";
import { API_PROJECT_URL } from "config";

export async function getProjectsByIds(projectIds: string[]): Promise<IPaged<IProject>> {
    const response = (await requestIns.get(
        `${API_PROJECT_URL}/project`,
        {
            params: {
                "filter.name": "ids_eq",
                "filter.params": `${projectIds.join(",")}`,
                "paging.page": 0,
                "paging.size": 99999,
            },
        },
    )).data;

    return response;
}

export async function getAllProjects(): Promise<IPaged<IProject>> {
    const response = (await requestIns.get(
        `${API_PROJECT_URL}/project`,
        {
            params: {
                "filter.name": "project_name_eq_and_project_area_eq",
                "filter.params": `null,null`,
                "paging.page": 0,
                "paging.size": 99999,
            },
        },
    )).data;

    return response;
}

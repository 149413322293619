import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Page } from "components";
import { chunk } from "utils/tools";
import { Auth } from "utils/authority";
import { PORTAL_MENUS } from "config/data";
import { ENVIRONMENT } from "config/environment";
import styles from "./index.module.css";

class Portal extends React.Component<RouteComponentProps> {
  public render() {
    return <Page>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            {/* <img src={require("static/images/logo_portal.png")} alt="logo" /> */}
            <span className={styles.title}>数字化管理平台</span>
          </div>
          <div className={styles.headerRight}></div>
        </div>
        <div className={styles.body}>
          <div className={styles.back} onClick={() => {
            window.location.href = `${ENVIRONMENT.innoecos_url}/`;
          }}>返回</div>
          <div className={styles.content}>
            {
              PORTAL_MENUS.map(menuBlock => {
                const auth = new Auth();

                const filteredMenuBlock = menuBlock.children.filter(
                  x => x.route && auth.hasRangeAuthorityWithoutProject(x.authKeys),
                );
                const hasValidChildren = filteredMenuBlock.length > 0;

                if (!hasValidChildren) {
                  return null;
                }

                return <div key={menuBlock.label} className={styles.menuBlock}>
                  <div className={styles.menuBlockTitle}>{menuBlock.label}</div>
                  <div className={styles.menuItems}>
                    {
                      chunk(filteredMenuBlock, 3).map((menuItemLine, lineIdx) => {
                        return <div key={lineIdx} className={styles.menuItemLine}>
                          {
                            menuItemLine.map(menuItem => {
                              return <div
                                key={menuItem.title}
                                className={styles.menuItem}
                                onClick={() => {
                                  if (menuItem.title === "企业服务") {
                                    if (window.location.origin.indexOf(".sit") >= 0) {
                                      const href =
                                        "http://ies-sit.innoecos.cn";
                                      window.open(href, "_blank");
                                    } else {
                                      const href =
                                        "https://ies.innoecos.cn";
                                      window.open(href, "_blank");
                                    }
                                  } else {
                                    const href = `${window.location.origin}/${menuItem.route}`;
                                    window.open(href, "_blank");
                                  }
                                }}
                              >
                                <img
                                  className={styles.menuItemIcon}
                                  src={menuItem.icon}
                                  alt="icon"
                                />
                                <span
                                  className={styles.menuItemTitle}
                                >
                                  {menuItem.title}
                                </span>
                                <img
                                  className={styles.menuItemNext}
                                  src={require("static/images/portal/icon_right.png")}
                                  alt="icon right"
                                />
                              </div>;
                            })
                          }
                        </div>;
                      })
                    }
                  </div>
                </div>;
              })
            }
          </div>
        </div>
      </div>
    </Page>;
  }
}

export default Portal;

import axios from "axios";
import { CLIENT_ID, CLIENT_SECRET } from "config";

export async function login(username: string, password: string): Promise<{
    access_token: string,
    token_type: string,
    refresh_token: string,
    expires_in: number,
    scope: string,
    jti: string,
}> {
    const response = (await axios.post(
        `/uaa/oauth/token`,
        `grant_type=password&username=${username}&password=${password}`,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic " + Buffer.from(`${CLIENT_ID}:${CLIENT_SECRET}`).toString("base64"),
            },
        },
    )).data;

    return response;
}

export async function createOAuthToken(iotToken: string): Promise<{
    access_token: string,
    expires_in: number,
    jti: string,
    scope: string,
    token_type: string,
}> {
    const response = (await axios.post(
        `/uaa/oauth/token`,
        `grant_type=aliiot_token&aliiot_token=${iotToken}`,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Basic " + Buffer.from(`${CLIENT_ID}:${CLIENT_SECRET}`).toString("base64"),
            },
        },
    )).data;

    return response;
}

export async function createToken(commonToken: string): Promise<any> {
    return axios({
        method: "post",
        url: "/api/dscloud-account-center/park/login",
        params: {
            grant_type: "common_token",
            endpoint: "web_yuanqu",
            common_token: commonToken,
        },
    });
}
